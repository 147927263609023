/**
 * Store all ajax query, and allow aborting by calling abort() function
 */
var xhrPool = [];
$(document).ajaxSend(function(e, jqXHR, options){
    //$('.spinner_loupe').addClass('hide');
    $('#spinner').removeClass('hide');
    xhrPool.push(jqXHR);
});
$(document).ajaxComplete(function(e, jqXHR, options) {
    $('#spinner').addClass('hide');
    //$('.spinner_loupe').removeClass('hide');
    xhrPool = $.grep(xhrPool, function(x){return x!=jqXHR});
});
var abort = function() {
    $('#spinner').addClass('hide');
    //$('.spinner_loupe').removeClass('hide');
    $.each(xhrPool, function(idx, jqXHR) {
        jqXHR.abort();
    });
};

jQuery(function($) {

    var filters = {};

    // Getting all olders filters
    $('.filterColumn #currentFilters .badge').each(function(index) {
        if(typeof filters[$(this).attr('name')] == 'undefined')
            filters[$(this).attr('name')] = [];

        filters[$(this).attr('name')].push($(this).attr('value'));
    });

    $('.cyb_search').on('input', function() {
        var search_url = $(this).data('search-url');
        var search = $(this).val();
        abort();

        if(search.length >= 3) {
            $.ajax({
                url: search_url,
                type: 'GET',
                data: { s: search },
                success: function(data) {
                    $('.search-hints').removeClass('d-none');
                    $('.miniCartTable').toggleClass('border1px');
                    $('.search-hints table tbody').html("");
                    for(var i=0; i < data.length; i++) {
                        $('.search-hints table tbody').append(data[i]);
                    }
                },
                error: function() {
                }
            });
        }
        else {
            $('.search-hints').addClass('d-none');
            $('.miniCartTable').removeClass('border1px');
            $('.search-hints table tbody').html("");
        }
    });

    $(".cyb-main-search .submit-div").on('click', function() {
        $('form.cyb-main-search').submit();
    });


    $('.filterColumn input:not(#filter_quantity), .filterColumn .current-filters .picto-remove').on('click', function() {
        if($(this).is('input'))
            var selectedFilter = $(this);
        else
            var selectedFilter = $(this).parent();

        if(selectedFilter.is('input') && selectedFilter.is(':checked')) {
            // Adding new filter
            if(typeof filters[selectedFilter.attr('name')] == 'undefined')
                filters[selectedFilter.attr('name')] = [];
            filters[selectedFilter.attr('name')].push(selectedFilter.attr('value'));
        }
        else {
            // Removing filter
            filters[selectedFilter.attr('name')] = $.grep(filters[selectedFilter.attr('name')], function(value) {
                return value != selectedFilter.attr('value');
            });
        }

        // concataining filters in strong with ; delimiter
        var values = {filters: {}};
        for(var prop in filters) {
            if(filters[prop].length > 0) {
                values['filters'][prop] = "";

                for(var i=0; i < filters[prop].length; i++) {
                    values['filters'][prop] += filters[prop][i]+";";
                }
            }
        }

        // If deselecting last filters, sending empty filter array
        var params = $.param(values);
        if(params == "") {
            params = "filters[]=";
        }
        var currentUrl = new URL(window.location.href);
        if (currentUrl.searchParams.get('s')) {
            params += ((params === '') ? 's=' : '&s=') + currentUrl.searchParams.get('s');
        }

        // Generating url
        var newUrl = "";
        var url = window.location.href;
        if($('.filterColumn').attr('data-url'))
            newUrl = $('.filterColumn').attr('data-url') + "?" + params;
        else {
            newUrl = window.location.href;
            if(url.indexOf("?") < 0)
                newUrl += "?" + params;
            else
                newUrl += "&" + params;
        }

        newUrl = addQuantityFilter(newUrl);
        window.location.href = newUrl;
    });

    $('#filter--quantity__submit').on('click', function(e) {
        e.preventDefault();
        var colors = [];
        $('.current-filters[name="color"]').each(function () {
           colors.push($(this).find('strong').html())
        });
        ga('send', {
            hitType: 'event',
            eventCategory: 'Filtre par quantité',
            eventAction: $('h1.page-title').html(),
            eventLabel: "qt: "+ $("#filter_quantity").val() + " + couleurs : " + colors.join(',')
        });
        window.location.href = addQuantityFilter(window.location.href);
    });

    $('#filter_quantity').on('change paste keyup', function () {
        if ($.trim($(this).val()) !== '') {
            $('#filter--quantity__submit').prop("disabled", false);
        } else {
            $('#filter--quantity__submit').prop("disabled", true);
        }
    });

    $('.filter--quantity__form').on('mouseenter', function () {
        if ($('#filter_quantity').is('[disabled=disabled]')) {
            $('#panel-heading__color').addClass('strong');
            $('.filter--quantity__help').popover('show')
        }
    });
    $('.filter--quantity__form').on('mouseleave', function () {
        $('.filter--quantity__help').popover('hide');
    });

    /**
     * Add Quantity on current url
     * @returns {string|null}
     */
    function addQuantityFilter(url) {
        var input = $('#filter_quantity');
        if (input.val() !== "") {
            var inputName = input.attr('name');
            var match = new RegExp(inputName + '=[0-9]+' , 'g');
            if (url.match(match)) {
                url = url.replace(match, inputName + '=' + input.val());
            } else {
                url = url + '&' + inputName + '=' + input.val() ;
            }
            return url;
        }
        return url;
    }


});
